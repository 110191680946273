<footer class="text-lg-start">
  <div *ngIf="showTopFooter" class="top-footer">
    <!-- Grid container -->
    <div class=" container p-4">
      <!--Grid row-->
      <div class="row">

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">

<!--    <h5 class="text-uppercase">Unviersity of Hyderabad</h5>  -->
          <ul class="list-unstyled mb-0">
            <li>
<!--              <a routerLink="https://uohyd.ac.in" class="">UoH Homepage</a>  -->
		<a href="https://uohyd.ac.in/">UoH Homepage</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">

<!--          <h5 class="text-uppercase">IGM Library</h5>   -->

          <ul class="list-unstyled mb-0">
            <li>

		<a href="https://library.uohyd.ac.in/">Library Homepage</a>

            </li>
          </ul>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </div>
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="bottom-footer p-1 d-flex justify-content-center align-items-center text-white">
    <div class="content-container">
      <p class="m-0">
        <a class="text-white"
           href="http://www.dspace.org/">{{ 'footer.link.dspace' | translate}}</a>
        {{ 'footer.copyright' | translate:{year: dateObj | date:'y'} }}
        <a class="text-white"
           href="https://www.lyrasis.org/">{{ 'footer.link.lyrasis' | translate}}</a>
      </p>
      <ul class="footer-info list-unstyled small d-flex justify-content-center mb-0">
        <li>
          <a class="text-white" href="javascript:void(0);"
             (click)="showCookieSettings()">{{ 'footer.link.cookies' | translate}}</a>
        </li>
	<!--
        <li>
          <a class="text-white"
             routerLink="info/privacy">{{ 'footer.link.privacy-policy' | translate}}</a>
        </li>
	-->
        <li>
          <a class="text-white"
             routerLink="info/feedback">{{ 'footer.link.feedback' | translate}}</a>
        </li>
      </ul>
    </div>
  </div>
  <!-- Copyright -->
</footer>
